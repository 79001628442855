import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';

const routeConfig: Routes = [
    {
        path: '',
        component: HomeComponent,
        title: 'Wesele Natalii i Adama'
    },
    {
      path: ':id',
      component: HomeComponent,
      title: 'Wesele Natalii i Adama'
    }
  ];
  
  export default routeConfig;