
<input type="file" class="file-input" accept=".jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*"
       (change)="onFileSelected($event)" #fileUpload>

<div class="file-upload">
    <div>
    <img [hidden]="!uploading" src="assets/arrow-up.svg" height="25" width="25">
    <img [hidden]="!done" src="assets/done.svg" height="25" width="25">
    <button class="rightButton" [disabled]="uploading"
      (click)="fileUpload.click()">
        <!--<img class="file-logo" src="/assets/attachment-svgrepo-com.svg"/>-->
        {{uploading? '...': 'Wyślij fotke'}}
    </button>
  </div>
</div>
  

