<section style="--width: {{width}}px; --height: {{height}}px">
  <div class="touchSensor" (touchstart)="posIni = {x:$event.changedTouches[0].pageX,y:$event.changedTouches[0].pageY}"
      (touchend)="move({x:$event.changedTouches[0].pageX,y:$event.changedTouches[0].pageY})">
    <div #carouselContainer (fullscreenchange)="removeAttributeWhenExitFullScreen()">
      <div #rotateContainer>
        <ngb-carousel #carousel (slide)="activeid=getActiveId($event.current)">	
          <ng-template ngbSlide *ngFor="let imageDetails of images; let currentIndex=index;" [id]="'slide-' + currentIndex">
              <app-slide [width]="width" [height]="height"  [imageDetailsArg]="imageDetails" *ngIf="shouldLoadImage(currentIndex,activeid)" (touchmove)="disableMove($event)"></app-slide>
          </ng-template>	 
        </ngb-carousel>  
      </div>
    </div>
    <button #fullScreenButton hidden (click)="displayFullscreen(carouselContainer, rotateContainer)"> Pełny ekran</button>
  </div>
</section>
