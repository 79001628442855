import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { ImagePipe } from '../imagePipe';
import { ImageDetails } from '../image-details';
import { DeleteImageService } from '../delete-image.service';

@Component({
  selector: 'app-thumbnail',
  standalone: true,
  imports: [CommonModule,ImagePipe],
  templateUrl: './thumbnail.component.html',
  styleUrl: './thumbnail.component.css'
})
export class ThumbnailComponent {
  @Input() imageDetailsArg!:ImageDetails
  @Input() isAdmin!:boolean
  @Input() width!:number;
  @Output() imageDeleted = new EventEmitter();
  deleteImageService: DeleteImageService = inject(DeleteImageService);
  loaded=false;

  delete(){
    const confirm = window.confirm('Czy napewno chcesz usunąć te zdjęcie')
    if(confirm)
      {
        const sub = this.deleteImageService.deleteImage(this.imageDetailsArg.downloadThumbnail).subscribe({next: data =>{
          this.imageDeleted.emit();
          sub.unsubscribe();
        }})
      }
  }
}
