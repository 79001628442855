import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthResponse } from './auth-response';

@Injectable({
  providedIn: 'any'
})
export class AuthService {

  private url:string = `${environment.apiUrl}/auth`
  
  constructor(private http: HttpClient) { }

  login(username:string, password:string, recaptchaToken:string){
    const data = {
      username,
      password,
      recaptchaToken
    }
  
    return this.http.post<AuthResponse>(this.url, data);
  }

  validateToken(token:string){
    let headers:HttpHeaders = new HttpHeaders();
    
    headers= headers.append("Authorization",token);
    console.log(headers);

    return this.http.post(`${this.url}/validate`,null,{ headers:headers })
  }

}
