<ul ngbNav #nav="ngbNav" [activeId]="active" (activeIdChange)="updateActiveId($event)" class="nav-tabs">
	<li ngbNavItem="1">
		<a [routerLink]="['/', 'slub']"><button ngbNavLink>Ślub</button></a>
		<ng-template ngbNavContent>
			<app-event-one-page [menuWidth]="10" [pageContent]="firstPage"></app-event-one-page>
		</ng-template>
	</li>
	<li ngbNavItem="2">
		<a [routerLink]="['/', 'wesele']"><button ngbNavLink>Wesele</button></a>
		<ng-template ngbNavContent>
			<app-event-one-page [menuWidth]="10" [pageContent]="secondPage"></app-event-one-page>
		</ng-template>
	</li>
	<li ngbNavItem="3">
		<a [routerLink]="['/', 'transport']"><button ngbNavLink>Transport</button></a>
		<ng-template ngbNavContent>
			<app-event-one-page [menuWidth]="10" [pageContent]="thirdPage"></app-event-one-page>
		</ng-template>
	</li>
	<li *ngIf="apiAvailable" ngbNavItem="4">
		<a [routerLink]="['/', 'zdjecia']"><button ngbNavLink>Zdjęcia</button></a>
		<ng-template ngbNavContent>
			<app-photos-page [menuWidth]="30"></app-photos-page>
		</ng-template>
	</li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>