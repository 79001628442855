import { Component} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { RecaptchaV3Module } from 'ng-recaptcha';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [HttpClientModule, CommonModule, HomeComponent, RouterModule, RecaptchaV3Module],
  template: `<router-outlet></router-outlet>`,
  styleUrls: ['./app.component.css'],
})

export class AppComponent {
  
}
