<div class="page">
    <div class="loginContainer">
        <div [hidden]="qrMode">
            <div>
            <label>Użytkownik:</label>
            <input #username class="loginElement">
            </div><br>
            <div>
            <label>Hasło:</label>
            <input #password class="loginElement" type="password" (keyup)="keyInPasswordPage($event.key, submitBtn)">
            </div><br>
            <div id="wrongPasswordMessage" class="message" [hidden]="!wrongPassword"> Zła nazwa uzytkownika lub hasło! <br></div>
            <button #submitBtn class="loginElement" (click) = "onSubmit(username.value, password.value, submitBtn)">
                Zaloguj
            </button><br>
            <button class="loginElement" (click)="turnOnCamera(action)">
                kod QR
            </button>
        </div>
        <div class="scanner-container" [hidden]="!qrMode">
            <!-- For camera -->
            <ngx-scanner-qrcode #action="scanner" [hidden]="!action.isStart" (event)=" validateQRCode(action.data.value, submitBtn); action.stop();"></ngx-scanner-qrcode>

            <!-- Loading -->
            <p *ngIf="action.isLoading">⌛ Prosze czekać...</p>
            <p *ngIf="wrongPassword && !action.isStart" style="color: red;"> Coś poszło nie tak. Spóbuj ponownie! <button (click)="action.start()"> Włacz kamerę </button><br></p>

            <!-- start -->
            <button class="loginElement" (click)="action.stop(); qrMode=false; wrongPassword=false;">Anuluj</button>
            <button class="loginElement" (click)="changeCamera(action)" [hidden]="!(action.devices.value.length>1)">Zmień kamerę</button>
        </div>
    </div>
</div>