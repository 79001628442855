import { Component, Input, ViewChild, inject } from '@angular/core';
import { CarouselComponentComponent } from '../carousel-component/carousel-component.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HostListener } from '@angular/core';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { LoginPageComponent } from '../login-page/login-page.component';
import { CommonModule } from '@angular/common';
import { AuthService } from '../auth.service';
import { ImagesService } from '../images.service';
import { PaginatedThumnailsComponent } from '../paginated-thumnails/paginated-thumnails.component';
import { ImageListResponse } from '../image-list-response';

@Component({
  selector: 'app-photos-page',
  standalone: true,
  imports: [CarouselComponentComponent, FileUploaderComponent, LoginPageComponent, CommonModule, MatProgressSpinnerModule, PaginatedThumnailsComponent],
  templateUrl: './photos-page.component.html',
  styleUrl: './photos-page.component.css'
})
export class PhotosPageComponent {
  @Input() menuWidth!:number;
  @ViewChild('carousel') carousel!:CarouselComponentComponent;
  fullScreenButtonHidden=false;
  isCarouselFullWidht=true;
  authService:AuthService=inject(AuthService);
  images:ImageListResponse = {updated:0,images:[] }
  imagesService: ImagesService = inject(ImagesService);
  checkForUpdatesTimerId!:number;

  headerHeight = 150;
  bottomMargin = 100;
  height:number = window.innerHeight - this.headerHeight - this.bottomMargin;
  pageWidth = window.innerWidth - this.menuWidth;
  width = this.pageWidth
  loaded:boolean=true;
  loggedIn:boolean=false;
  isAdmin:boolean=false;

  calculateCarouselDimensions(){
    let screenWidth = window.innerWidth>window.outerWidth?window.outerWidth:window.innerWidth;
    this.pageWidth= screenWidth - this.menuWidth - 30;
    this.width = this.pageWidth
    let calculatedHeight = window.innerHeight - this.headerHeight - this.bottomMargin;
    this.height = calculatedHeight >300? calculatedHeight :300;
    if(this.height * 16/9 < this.width){
      this.width = this.height * 16/9;
    }
    if(this.width * 9/16<this.height)
    {
      this.height= this.width * 9/16
    }

    this.isCarouselFullWidht= this.width === this.pageWidth
  }

  ngAfterViewInit(){
    this.calculateCarouselDimensions();
    this.updateLoggedIn();
    if(navigator.userAgent.indexOf('iPhone')>-1){
      this.fullScreenButtonHidden = true;
    }
    
    if(navigator.userAgent.indexOf('iPad')>-1){
      this.fullScreenButtonHidden = true;
    }

    if(navigator.userAgent.indexOf('iPod')>-1){
      this.fullScreenButtonHidden = true;
    }
  }

  ngOnDestroy(){
    clearInterval(this.checkForUpdatesTimerId);
  }

  checkIfAdmin(token:string):boolean{
    return JSON.parse(atob(token.split('.')[1])).role==='admin';
  }

  updateLoggedIn()
  {    
      let token= localStorage.getItem("token");
      if(token){
         this.loaded=false;
         const sub =this.authService.validateToken(token).subscribe({
          next: () =>{
            this.loaded=true;
            this.loggedIn = true;
            this.isAdmin = this.checkIfAdmin(token||'');
            if(this.isAdmin){
              window.alert('Jesteś zalogowany jako administrator!');
            }
            this.checkUpdates();
            this.checkForUpdatesTimerId = setInterval(() => {this.checkUpdates()},30000,null)
            this.hideRecaptcha();
            sub.unsubscribe();

          },
          error: () => {
            this.loaded=true;
            this.loggedIn = false; 
            this.isAdmin = false;
            localStorage.removeItem("token");
            sub.unsubscribe();
          }
         })
      }else{
        console.log('no token')
        this.loaded=true;
      } 
  }
  
  hideRecaptcha() {
    const elements = document.querySelectorAll('.grecaptcha-badge');
    console.log(`${elements.length}`);
    if (elements.length) return elements[0].setAttribute('style', 'display: none');
    requestAnimationFrame(() => this.hideRecaptcha());
}

  checkUpdates(){
    const subscription = this.imagesService.getAllImages().subscribe(data => {
      if(this.images.updated<data.updated){
        this.images=data;
      }
      console.log(`images: ${this.images}`)
      subscription.unsubscribe();
    })
  }

  showUploadedFile(carouselContainer:HTMLElement){
    const uploadSubscription = this.imagesService.getAllImages().subscribe(data => {
      if(this.images.updated<data.updated){
        this.images=data;
        window.alert('Dodano zdjęcie! Dzięki!')
        setTimeout(() => {
          this.carousel.goToSlide(this.images.images.length-1);
          carouselContainer.scrollIntoView({behavior:"smooth"});
         }, 1000);
        
        
        uploadSubscription.unsubscribe();
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculateCarouselDimensions();
  }

  thumbnailClicked(id:number, carouselContainer:HTMLElement){
    this.carousel.goToSlide(id);
    carouselContainer.scrollIntoView({behavior:"smooth"});
  }

  logout(){
    localStorage.removeItem("token");
    this.loggedIn = false;
    this.isAdmin = false;
  }

  fullWidth(){
    this.width = this.pageWidth;
    const calculatedHeight = this.width * 9/16;
    this.height= window.screen.availHeight < calculatedHeight ? window.screen.availHeight : calculatedHeight;
    this.isCarouselFullWidht= this.width === this.pageWidth
  }

}
