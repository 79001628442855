<div style="width: fit-content; margin: auto;">
    <div class="thumnailsContainer" style="--width: {{width}}px;">
    @for (imageDetails of images | slice: (page-1) * pageSize : page * pageSize; track imageDetails; let index=$index ) {    
            <div (click)="thumbnailClickedInternal(index + (page-1) * pageSize)">
                    <app-thumbnail [isAdmin]="isAdmin" [width]="width/3-20" [imageDetailsArg]="imageDetails" (imageDeleted)="deletedImage()"></app-thumbnail>
            </div>       
    }
    </div>  
    <div style="margin: auto; width: fit-content;">
    <ngb-pagination style="margin: auto;"
            [collectionSize]="images.length"
            [(page)]="page"
            [maxSize]="5"
            [pageSize]="pageSize"
            [rotate]="true"
            [ellipses]="false"
            [boundaryLinks]="true"
        />
    </div>
</div>