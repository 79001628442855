import { Component } from '@angular/core';
import { HostListener } from '@angular/core';
import { Input } from '@angular/core';
import { PageContent } from '../page-content';
import { SafePipe } from '../safePipe';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-event-one-page',
  standalone: true,
  imports: [SafePipe,CommonModule],
  templateUrl: './event-one-page.component.html',
  styleUrl: './event-one-page.component.css'
})

export class EventOnePageComponent {
  @Input() menuWidth!:number;
  @Input() pageContent!:PageContent;
  width = window.innerWidth;
  vericalLayout = this.width < 700;
  horizontalLayout = !this.vericalLayout;
  maxWidth= 1000;

  ngOnInit(){
    let screenWidth =window.innerWidth>window.outerWidth?window.outerWidth:window.innerWidth;
    this.width = screenWidth - this.menuWidth - 20;
    this.vericalLayout = this.width < 700;
    this.horizontalLayout = !this.vericalLayout;
    console.log(`width:${this.width}`)
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    let screenWidth =window.innerWidth>window.outerWidth?window.outerWidth:window.innerWidth;
    this.width = screenWidth - this.menuWidth - 20;
    this.vericalLayout = this.width < 700;
    this.horizontalLayout = !this.vericalLayout;
    console.log(`width:${this.width}`)
  }
}
