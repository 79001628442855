<section class="photosPage" style="--width: {{pageWidth}}px">
    <mat-spinner class="centered" color="warn" *ngIf="!loaded"></mat-spinner>
    <app-login-page *ngIf="!loggedIn && loaded" (loggedIn)="updateLoggedIn()"></app-login-page>
    <div class="centered">
        <table *ngIf="loggedIn && loaded">
            <tr>
                <div class="logoutContainer">
                    <a (click)="logout()">Wyloguj</a>
                </div>
            </tr>
            <tr #carouselContainer>
                <app-carousel-component #carousel [widthInitial]="width" [heightInitial]="height" [images] = "images.images"></app-carousel-component>
            </tr>
            <tr>
                <div style="margin-left: auto; width: fit-content;">
                    <div style="display: flex;">
                        <button [hidden]="!fullScreenButtonHidden || isCarouselFullWidht" style="margin-right: 5px;" (click)="fullWidth()">Pełny Ekran</button>
                        <button [hidden]="fullScreenButtonHidden" style="margin-right: 5px;" (click)="carousel.makeFullScreen()">Pełny Ekran</button>
                        <app-file-uploader (fileUploaded)="showUploadedFile(carouselContainer)"></app-file-uploader>
                    </div>
                </div>
            </tr>
            <tr>
                <app-paginated-thumnails [isAdmin]="isAdmin" [width]="width" [images]="images.images" (thumbnailClicked)="thumbnailClicked($event, carouselContainer)" (imageDeleted)="checkUpdates()"></app-paginated-thumnails>
            </tr>
        </table>
    </div>
</section>