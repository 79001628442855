<div class="eventOnePage" style="--width: {{width}}px">
    <section class="content" style="--maxWidth: {{maxWidth}}px">
    <h2> {{pageContent.title}} </h2>
    <div>
        <div> 
            <table [width]="width<maxWidth?width-30:maxWidth-30">
                <tr>
                    <td class="text">
                        <div [innerHTML]="pageContent.text"></div>
                    </td>
                    <td [hidden]="vericalLayout">
                        <table class="rightAligned">
                            <td class="rightContent">
                                <img class="photo" [src]="pageContent.imageSrc">
                            </td>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td [hidden]="horizontalLayout">
                    <table style="margin:auto">
                        <td>
                            <img class="vericalPhoto" [src]="pageContent.imageSrc" [width]="width - 30">
                        </td>
                    </table>
                </td></tr>
            </table>
            <table [hidden]="pageContent.middleText===undefined">
                <tr>
                    <td class="middleTest"><div [innerHTML]="pageContent.middleText"></div></td>
                </tr>
            </table>
        </div>
        <table class="info" [width]="width<maxWidth?width-30:maxWidth-30">
            <tbody>
                <tr id="maps" [hidden]="horizontalLayout">
                    <table >
                        <td>
                        <iframe [src]="pageContent.mapsSrc | safe"
                            [width]="width - 30" height="225" style="border:0;" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </td>    
                        
                    </table>
                </tr>
                <tr>
                    <td id="eventOneData">
                        <table>
                            <tbody>
                                <tr class="details-row" *ngFor="let detail of pageContent.details">
                                    <td class="label">{{detail.key}}</td>
                                    <td class="value">{{detail.value}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                    <td [hidden]="vericalLayout">
                        <table class="rightAligned">
                            <td class="rightContent">
                            <iframe [src]="pageContent.mapsSrc | safe"
                                [width]="width<maxWidth?width*7/10-30:maxWidth*7/10-30" height="225" style="border:0;" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </td>    
                            
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    </section>
</div>
