import { Injectable, } from '@angular/core';
import { ImageDetails } from './image-details';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ImageListResponse } from './image-list-response';

@Injectable({
  providedIn: 'any',
})

export class ImagesService {

  private url:string = `${environment.apiUrl}/images`
  
  constructor(private http: HttpClient) { }

  getAllImages() {
    let token= localStorage.getItem("token");
    let headers:HttpHeaders = new HttpHeaders().set("Authorization",token ? token: "") ;
    return this.http.get<ImageListResponse>(this.url, {headers});

  }
}
