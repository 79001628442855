import { Component, EventEmitter, Output, ViewChild, ViewChildren, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxScannerQrcodeModule, LOAD_WASM, ScannerQRCodeResult, NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
import { AuthService } from '../auth.service';
import {  ReCaptchaV3Service, } from 'ng-recaptcha';

@Component({
  selector: 'app-login-page',
  standalone: true,
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.css',
  imports: [NgxScannerQrcodeModule, CommonModule]
})
export class LoginPageComponent {
  @Output() loggedIn = new EventEmitter();
  qrMode=false;
  wrongPassword:boolean=false;
  authService:AuthService = inject(AuthService);
  jwt:string="";

  constructor(private recaptchaV3Service: ReCaptchaV3Service) {
    LOAD_WASM().subscribe();
  }

  changeButtonMode(btn:HTMLElement, enabled:boolean){
    btn.setAttribute("disabled",enabled?'false':'true');
    if(!enabled)
      btn.setAttribute('style','color:grey; border-color:grey;')
    else{
      btn.removeAttribute('style')
      btn.removeAttribute('disabled')
    }
  }

  onSubmit(username:string, password:string,submitBtn:HTMLElement){
        this.changeButtonMode(submitBtn,false)
        this.wrongPassword=false;

        const sub =this.recaptchaV3Service.execute('importantAction').subscribe({
          next:(token)=>{
            const innersub=this.authService.login(username,password,token).subscribe({
              next: (data) => {
                this.wrongPassword = data.jwt != null;
                this.jwt= data.jwt;
                localStorage.setItem("token",this.jwt);
                this.loggedIn.emit();
                this.changeButtonMode(submitBtn,true)
                this.hideRecaptcha();
                innersub.unsubscribe();
              },
              error: (e) => {
                this.wrongPassword = true
                this.changeButtonMode(submitBtn,true)
                innersub.unsubscribe();
              },
            });
            sub.unsubscribe();
          }})        
  }

  hideRecaptcha() {
    const elements = document.querySelectorAll('.grecaptcha-badge');
    console.log(`${elements.length}`);
    if (elements.length) return elements[0].setAttribute('style', 'display: none');
    requestAnimationFrame(() => this.hideRecaptcha());
}

  keyInPasswordPage(key:string, submitButton:HTMLElement){
    if( key === 'Enter'){
      submitButton.click();
    }
  }

  validateQRCode(data:ScannerQRCodeResult[], submitBtn:HTMLElement){
    this.wrongPassword=false;
    if(data.length>0){
      console.log(data[0].value);
      let qrData=data[0].value.split('|');
      if(qrData.length===2){
        let username = qrData[0];
        let password = qrData[1];

        this.onSubmit(username,password, submitBtn);
        return;
      }   
    }

    this.wrongPassword=true;
  }

  changeCamera(scanner: NgxScannerQrcodeComponent){
    if(scanner.devices.value){
      if(scanner.deviceIndexActive!=scanner.devices.value.length-1)
      {
        scanner.playDevice(scanner.devices.value[scanner.deviceIndexActive + 1].deviceId);
      } else{
        scanner.playDevice(scanner.devices.value[0].deviceId);
      }
      console.log(`actual index is ${scanner.deviceIndexActive} id is equal ${scanner.devices.value[scanner.deviceIndexActive].deviceId}`)
    }
  }

  turnOnCamera(scanner: NgxScannerQrcodeComponent){
    this.wrongPassword=false;
    this.qrMode=true; 
    const playDevice = () =>{scanner.playDevice(scanner.devices.value[scanner.devices.value.length-1].deviceId)}
    scanner.start(playDevice);
  }
}
