import { Injectable, } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'any',
})

export class DeleteImageService {

  
  constructor(private http: HttpClient) { }

  deleteImage(thumbnailLink:string) {
    let token= localStorage.getItem("token");
    let headers:HttpHeaders = new HttpHeaders().set("Authorization",token ? token: "").set("Content-Type", "application/json") ;
    return this.http.delete(thumbnailLink, {headers});

  }
}
