import { Component, Output } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { EventEmitter } from "@angular/core";
import { environment } from "../../environments/environment";


@Component({
  selector: 'app-file-uploader',
  standalone: true,
  templateUrl: "file-uploader.component.html",
  styleUrls: ["file-uploader.component.css"]
})
export class FileUploaderComponent {
    @Output() fileUploaded = new EventEmitter();
    uploading=false;
    done=false;

    fileName:string|undefined= '';
    fileSelected:boolean=false;

    constructor(private http: HttpClient) {}

    onFileSelected(event:Event) {
        let fileList = (<HTMLInputElement>event.target).files;

        if (fileList?.length && fileList.length>0) {
            this.uploading=true;
            this.done=false;
            let file:File= fileList[0];
            this.fileName = file.name;

            const formData = new FormData();

            formData.append("foo", file);
            let token= localStorage.getItem("token");
            let headers:HttpHeaders = new HttpHeaders().set("Authorization",token ? token: "") ;

            const upload$ = this.http.post(`${environment.apiUrl}/images/upload`, formData, {headers});

            const sub = upload$.subscribe(response =>{
                console.log(response);
                this.fileSelected=true;
                this.fileUploaded.emit();
                this.uploading=false;
                this.done=true;
                sub.unsubscribe();
            });  
        }
    }
}