import { Component, HostListener, inject } from '@angular/core';
import { PageContent } from '../page-content';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { navHorizontalComponent } from '../nav-horizontal/nav-horizontal.component';
import { NavComponent } from '../nav/nav.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [navHorizontalComponent,NavComponent, CommonModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  route = inject(ActivatedRoute);
  title = 'Homes';
  width = window.innerWidth;
  vericalLayout = this.width < 850;
  horizontalLayout = !this.vericalLayout;
  active="1";
  apiAvailable = false;

  displayProperPageBasedOnUrl(){
    let requestedPage:string =this.route.snapshot.params['id'];
    if(requestedPage){
      switch(requestedPage.toLowerCase()){
        case 'slub':{
          this.active="1";
          break;
        }
        case 'wesele':{
          this.active="2";
          break;
        }
        case 'transport':{
          this.active="3";
          break;
        }
        case 'zdjecia':{
          this.active="4";
          break;
        }
        default:{
          this.active="1";
        }
      }
    }    
  }

  constructor(private http: HttpClient) {
    this.displayProperPageBasedOnUrl()
  }

  firstPage:PageContent= {
    title:"Ślub - informacje",
    imageSrc:"/assets/kosciol.jpg",
    mapsSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2559.1555618506995!2d18.983048276415982!3d50.10209517152778!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716b7f79d9033f9%3A0x3f191e31ec7803bf!2sRoman%20Catholic%20parish%20of%20Blessed.%20Carolina!5e0!3m2!1sen!2spl!4v1707733524794!5m2!1sen!2spl",
    text:"Nasz ślub odbędzie się 13 kwietnia 2024 roku o godzinie 13:00 w parafii pod wezwaniem Błogosławionej Karoliny w Tychach. Będzie nam niezmiernie miło kiedy będziemy mogli dzielić się tą ważną dla nas chwilą z Wami. </br></br>" + 
    "<div class='quoted'>„Nowi ludzie... dotąd dwoje, lecz jeszcze nie jedno, odtąd jedno, chociaż nadal dwoje\”</div>",
    details: [{
      key:"Data:",
      value:"13.04.2024r"
    },
    {
      key:"Godzina:",
      value:"13:00"
    },
    {
      key:"Parafia:",
      value:"pw Bł. Karoliny w Tychach"
    },
    {
      key:"Adres:",
      value:"Tischnera 50, 43-100 Tychy"
    }]
  }

  secondPage:PageContent= {
    title:"Wesele - informacje",
    imageSrc:"/assets/karczma.jpg",
    mapsSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2566.922686611566!2d18.96444147637299!3d49.95655277150212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716a3768f3220d3%3A0xe239d83f713e618b!2sKUBAN!5e0!3m2!1sen!2spl!4v1707761062619!5m2!1sen!2spl",
    text:"Poniżej znajdują się informacje na temat imprezy weselnej </br>" + 
        "Impreza odbędzie się w karczmie Kuban w Goczałkowicach</br></br>" +
        "<p class='quoted'>\"Gościom nisko się kłaniamy, na wesele zapraszamy.</br>Będą pląsy, tańce, bale i jedzenia stosy całe.</br>Więc się nasi mili stawcie i się bardzo dobrze bawcie!\"</p>",
    middleText:"<div style='display: flex;' >Zachęcamy do instalacji aplikacji&nbsp;<img src='assets/KahootLogo.png' height='25px;'></br>" +
    "Która to może okazać się przydatna podczas naszego wesela (na pewno będzię się to opłacało&nbsp;:)&nbsp;) <ul><li><a href='https://play.google.com/store/apps/details?id=no.mobitroll.kahoot.android&referrer=utm_source%3Dkahoot%26utm_campaign%3Dmobileapp' target='_blank'><img src='assets/googlePlay.png' height='45px;'></a></li><li><a href='https://itunes.apple.com/app/apple-store/id1131203560?pt=2314983&ct=kahootcommobileapp&mt=8' target='_blank'><img src='assets/apple.png' height='45px;'></a></li></ul>" + 
    "</div>" +
    `<br>
    <div>Plan Wesela (podane godziny są bardzo elastryczne):
      <ul>
        <li>14:30 Przywitanie i życzenia</li>
        <li>15:30 Obiad</li>
        <li>17:00 Tort i Ciasta</li>
        <li>18:30 Zimna płyta</li>
        <li>20:30 1. Kolacja</li>
        <li>22:00 Lody</li>
        <li>23:30 2. Kolacja</li>
        <li>00:00 Oczepiny</li>
        <li>02:00 3. Kolacja</li>
      </ul>
    </div>`,
    details: [{
      key:"Data:",
      value:"13.04.2024r"
    },
    {
      key:"Godzina:",
      value:"Po Ślubie"
    },
    {
      key:"Gdzie:",
      value:"Karczma Kuban"
    },
    {
      key:"Ulica:",
      value:"Spokojna 72"
    },
    {
      key:"Miasto:",
      value:"43-230 Goczałkowice-Zdrój"
    }]
  }

  thirdPage:PageContent= {
    title:"Transport - informacje",
    imageSrc:"/assets/bus.png",
    mapsSrc:"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d759.8297463252422!2d18.898403334055764!3d50.16576641164654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716ca2eb40528e7%3A0xea4400d6f4cda92f!2sIndoor%20swimming-pool%20Aqua%20Plant!5e0!3m2!1sen!2spl!4v1707824471303!5m2!1sen!2spl",
    text:"Podczas imprezy weselnej zapewniony zostanie odwóz gośći w godzinach nocnych. </br></br>" + 
        "Dodatkowo możliwy będzię przejazd autobusem (<b>Chętnych prosimy o kontakt!</b>): <ul>" +
        "<li>Mikołów (Aqua Plant) → Tychy  (Kośćiół pw Bł. Karoliny)</li>" +
        "<li>Tychy (Kośćiół pw Bł. Karoliny) → Karczma Kuban</li>" +
        "</ul>" + 
        "W nocy autobus będzię powracał przeciwną trasą około 03:30</br>" +
        "",
    details: [{
      key:"Gdzie:",
      value:"Mikołów - Aqua Plant"
    },
    {
      key:"Godzina:",
      value:"12:00"
    },
    {
      key:"Ulica:",
      value:"Konstytucji 3 Maja 22"
    },
    {
      key:"Miasto:",
      value:"43-190 Mikołów"
    }]
  }

  updateActive(newActiveId:string){
    this.active=newActiveId;
  }

  ngDoCheck(){
    this.displayProperPageBasedOnUrl();
  }

  ngAfterViewInit(){
    this.width = window.innerWidth;
    const sub=this.http.get<{message:string}>(environment.apiUrl + '/status').subscribe({
      next: (response) => {
        if(response.message.indexOf('Is ok')>-1){
          this.apiAvailable=true;
          console.log(`api available=${this.apiAvailable}`)
        }
        sub.unsubscribe();
      }
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.width = window.innerWidth;
    this.vericalLayout = this.width < 1000;
    this.horizontalLayout = !this.vericalLayout;
  }
}
