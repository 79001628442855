import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';


@Pipe({
    name: 'imagePipe',
    standalone: true
})
export class DownloadPipe implements PipeTransform {

    constructor(private http: HttpClient) { }

    transform(url:string): Observable<string> {
      let token = localStorage.getItem("token");
      console.log("test");
      let headers = new HttpHeaders().set('Authorization',token?token:'');

        return this.http
            .get(url, { responseType: 'blob', headers }).pipe(map(val => URL.createObjectURL(val)));
    } 
}