import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ThumbnailComponent } from '../thumbnail/thumbnail.component';
import { CommonModule } from '@angular/common';
import { ImageDetails } from '../image-details';

@Component({
  selector: 'app-paginated-thumnails',
  standalone: true,
  imports: [ThumbnailComponent, NgbPaginationModule, CommonModule],
  templateUrl: './paginated-thumnails.component.html',
  styleUrl: './paginated-thumnails.component.css'
})
export class PaginatedThumnailsComponent {
  @Input() width!:number;
  @Input() images!:ImageDetails[]
  @Input() isAdmin!:boolean;
  @Output() thumbnailClicked = new EventEmitter<number>();
  @Output() imageDeleted = new EventEmitter();
  page=1;
  pageSize=6;

  deletedImage(){
    this.imageDeleted.emit();
  }

  thumbnailClickedInternal(index:number){
    this.thumbnailClicked.emit(index)
  }

}
