import { Component, ElementRef, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbCarouselConfig, NgbCarouselModule, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { SlideComponent } from '../slide/slide.component';
import { ImageDetails } from '../image-details';
import { ImagesService } from '../images.service';
import { Input } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-carousel-component',
  standalone: true,
  imports: [CommonModule, NgbCarouselModule, SlideComponent,HttpClientModule, MatProgressSpinnerModule],
  templateUrl: './carousel-component.component.html',
  styleUrl: './carousel-component.component.css',
  providers: [NgbCarouselConfig],
})
export class CarouselComponentComponent {
  @Input() widthInitial!:number;
  @Input() heightInitial!:number;
  @Input() images!:ImageDetails[]
  width:number=0;
  height:number=0
  activeid: number = 0;
  @ViewChild('fullScreenButton',{read: ElementRef}) 
  fullScreenButton!:ElementRef<HTMLElement>;

  rotateElement: HTMLElement | null = null;
  goToSlide(index:number){
    this.carousel.select(`slide-${index}`)
    this.carousel.pause();
    this.carousel.cycle();
  }
  
  ngOnInit(){
    this.width=this.widthInitial;
    this.height=this.heightInitial;
  }

  //images = [700, 533, 807, 124].map((n) => `https://picsum.photos/id/${n}/900/500`);

  getActiveId = function(input:string):number{return 0}
  shouldLoadImage=function(currentIndex:number, activeId:number):boolean{return true}

  @ViewChild('carousel')  carousel!: NgbCarousel;
  posIni:{ x:number, y:number} = {x:0,y:0};
  move(pos:{ x:number, y:number}) {
    let offset = 0;
    if (!this.rotateElement || !this.rotateElement.getAttribute("class")){
      offset = this.posIni.x - pos.x;
    }else{
      offset = pos.y - this.posIni.y 
    }
    if (offset < -50) { 
      this.carousel.prev();
      this.carousel.pause();
      this.carousel.cycle();
    }

    if (offset > 50) {
      this.carousel.next();
      this.carousel.pause();
      this.carousel.cycle();
    }
  }
  
	constructor(config: NgbCarouselConfig) {
		// customize default values of carousels used by this component tree
		config.interval = 10000;
		config.wrap = true;
		config.keyboard = true;
		config.pauseOnHover = false;
    config.animation=true;    

    

    this.getActiveId = function(input:string):number {
      const indexPrefix="slide-"
      if(input.indexOf(indexPrefix)!=0)
        return 0;
      return parseInt(input.substring(indexPrefix.length), 10);
    }
  
    this.shouldLoadImage=function(currentIndex:number, activeId:number):boolean{
      let previousIndex = activeId === 0 ? this.images.length - 1 : activeId -1;
      let nextIndex = activeId === this.images.length - 1 ? 0 : activeId + 1;
      return currentIndex === activeId || currentIndex === nextIndex || currentIndex === previousIndex;
    }
	}

  displayFullscreen(element:HTMLElement, rotateElemente:HTMLElement){
    element.requestFullscreen(); 
    this.rotateElement=rotateElemente;
    this.calculateDimensionInFullScreen();
    this.ngOnChanges();    
  }

  calculateDimensionInFullScreen(){
    this.width = window.screen.width
    this.height = window.screen.height
    if(this.width<this.height){
      let temp=this.width;
      this.width=this.height;
      this.height=temp;
      this.rotateElement?.setAttribute("class","rotated");
      console.log(`width:${this.width},height:${this.height} rotated`);
    }else{
      this.rotateElement?.removeAttribute("class");
    }
    //this.reloadIndex=this.reloadIndex+1;
  }

  removeAttributeWhenExitFullScreen(){
    if(!document.fullscreenElement){
      this.rotateElement?.removeAttribute("class");
      this.width=this.widthInitial;
      this.height=this.heightInitial;
      console.log(`width:${this.width},height:${this.height} unrotated`);
      this.rotateElement=null;
    }
  }

  makeFullScreen(){
    console.log("clicked");
    const htmlElement = this.fullScreenButton.nativeElement;
    htmlElement.click();
  }

  ngOnChanges() {
    if(document.fullscreenElement)
    {
      this.calculateDimensionInFullScreen()
    }else{
      if(this.rotateElement == null){
        this.width=this.widthInitial;
        this.height=this.heightInitial;
        console.log(`width:${this.width},height:${this.height} ngOnChange`);
      }
    }

    }

    disableMove(element:TouchEvent){
      element.preventDefault();
    }
}

