    <div class="picsum-img-wrapper">
        <section *ngIf="!loaded" style="--maxwidth: {{width}}px; --maxheight: {{height}}px" class="placeholder">
            <mat-spinner class="spinner" style="--margin-top: {{height/2}}px" color="warn" *ngIf="!loaded"></mat-spinner>
        </section>
            <a #link [download]="filename"></a>
            <button #downloadButton style="--left: {{width * 3 / 20 }}px" type="button" class="downloadButton" (click)="downloadClicked(link, downloadButton)">{{downloadButtonText}}</button>
            <div class="imageWrapper">
            <img class='image' [hidden]="!loaded" [attr.src]="imageDetailsArg.src | imagePipe | async" style="--maxwidth: {{width}}px; --maxheight: {{height}}px" (load)="loaded = true" />
            </div>

    </div>
    <div class="carousel-caption">
        <h3>{{imageDetailsArg.title}}</h3>
    </div>