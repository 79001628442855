
<div class="d-flex">
	<div ngbNav #nav="ngbNav" [activeId]="active" (activeIdChange)="updateActiveId($event)" class="nav-pills flex-column" orientation="vertical">
		<ng-container ngbNavItem="1">
			<a [routerLink]="['/', 'slub']"><button ngbNavLink>Ślub</button></a>
			<ng-template ngbNavContent>
				<app-event-one-page [menuWidth]="150" [pageContent]="firstPage"></app-event-one-page>
			</ng-template>
		</ng-container>
		<ng-container ngbNavItem="2">
			<a [routerLink]="['/', 'wesele']"><button ngbNavLink>Wesele</button></a>
			<ng-template ngbNavContent>
				<app-event-one-page [menuWidth]="150" [pageContent]="secondPage"></app-event-one-page>
			</ng-template>
		</ng-container>
		<ng-container ngbNavItem="3">
			<a [routerLink]="['/', 'transport']"><button ngbNavLink>Transport</button></a>
			<ng-template ngbNavContent>
				<app-event-one-page [menuWidth]="150" [pageContent]="thirdPage"></app-event-one-page>
			</ng-template>
		</ng-container>
		<ng-container  ngbNavItem="4">
			<a [routerLink]="['/', 'zdjecia']"><button *ngIf="apiAvailable" ngbNavLink>Zdjęcia</button></a>
			<ng-template ngbNavContent>
				<app-photos-page [menuWidth]="150"></app-photos-page>
			</ng-template>
		</ng-container>
	</div>
	<div [ngbNavOutlet]="nav" class="ms-4"></div>
</div>
