import { Component, ElementRef, Input, Output, QueryList, ViewChildren, inject } from '@angular/core';
import { ImageDetails } from '../image-details';
import { HostListener } from '@angular/core';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { ImagePipe } from '../imagePipe';
import { DownloadPipe } from '../downloadPipe';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-slide',
  standalone: true,
  imports: [NgbCarouselModule, SlideComponent, ImagePipe, CommonModule, MatProgressSpinnerModule],
  templateUrl: './slide.component.html',
  styleUrl: './slide.component.css'
  
})
export class SlideComponent {
  @Input() imageDetailsArg!:ImageDetails;
  @Input() width!:number;
  @Input() height!:number;

  constructor(private http: HttpClient) {}
  

  downloadPipe = new DownloadPipe(this.http);
  loaded=false;
  downloadFile=false
  downloadHref: string="";
  filename = "";
  downloadButtonText:string="Pobierz"

  ngOnInit(){
    this.filename =this.imageDetailsArg.downloadFull.substring(this.imageDetailsArg.downloadFull.lastIndexOf('/') + 1);
  }

  downloadClicked(el: HTMLElement, button:HTMLElement){
    button.setAttribute("disabled","true");
    this.downloadButtonText="Pobieranie ...";
    const downloadPipe = this.downloadPipe.transform(this.imageDetailsArg.downloadFull).subscribe(url => {
      this.downloadHref=url;
      el.setAttribute("href",this.downloadHref);
      console.log(this.downloadHref);
      while( el.getAttribute("href")!=this.downloadHref){
        console.log("href not update");
        console.log(el.getAttribute("href"));
      }
      button.removeAttribute("disabled")
      el.click();
      this.downloadButtonText="Pobierz";
      downloadPipe.unsubscribe();
     });
  }
}


