import { Component, EventEmitter, Output } from '@angular/core';
import { PhotosPageComponent } from '../photos-page/photos-page.component';
import { EventOnePageComponent } from '../event-one-page/event-one-page.component';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { Input } from '@angular/core';
import { PageContent } from '../page-content';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';


@Component({
	selector: 'ngbd-nav-vertical',
	standalone: true,
	imports: [NgbNavModule, NgbNavModule, PhotosPageComponent,EventOnePageComponent, CommonModule, RouterLink],
	templateUrl: './nav.component.html',
  styleUrl: './nav.component.css'
})
export class NavComponent {
	@Input() firstPage!:PageContent
	@Input() secondPage!:PageContent
	@Input() thirdPage!:PageContent
	@Input() active:string = "1";
	@Input() apiAvailable:boolean = false;
	@Output() newActiveId = new EventEmitter<string>();

	updateActiveId(newId:string){
		this.active = newId;
		this.newActiveId.emit(this.active)
	}

}